<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row>
					<a-col :span="6">
						<a-row style="margin-bottom: 20px;">
							<a-col :span="24">
								<!-- 新增 -->
								<a-col :span="3">
									<a-icon type="plus" @click="create"/>
								</a-col>
								<!-- 修改 -->
								<a-col :span="3">
									<a-icon type="edit" @click="revise"/>
								</a-col>
								<!-- 删除 -->
								<a-col :span="3">
								    <a-icon type="delete" @click="pointDatchdelete"/>
								</a-col>
							</a-col>
							<a-col :span="24">
								<div style="width:100%;overflow:auto;" :style="styObj">
									<div class="con" style="width: 100%;min-width: 180px;">
										<a-spin :spinning="isTreeLoading">
											<a-tree
													style="width:100%"
													:expanded-keys="expandedTreeKeys"
													:selected-keys="selectedTreeKeys"
													v-model="checkedTreeKeys"
													:tree-data="treeData"
													:replace-fields="treeReplaceFields"
													@select="onTreeSelect"
													@expand="onTreeExpand"
													v-if="treeData.length > 0"
											/>
											<div style="width: 100%;height: 100px;text-align: center;line-height: 100px;font-size: 20px;" v-else>{{l('Add_an_audit_grouping')}}</div>
										</a-spin>
								</div>
								</div>
							</a-col>
						</a-row>
					</a-col>
					<a-col :span="18" style="padding-left: 10px;">
						<a-row style="margin-bottom: 20px;">
							<a-col :span="10">
								<a-button type="primary" @click="createOrEdit('create')" v-if="isGranted('audit_template_create')">
									<a-icon type="plus" />
									{{l('Create')}}
								</a-button>
								<!-- <a-button type="primary" @click="createOrEditMachineSupplier('edit')">
									<a-icon type="edit"/>
									修改
								</a-button> -->
								<a-button type="danger" @click="batchdelete" v-if="isGranted('audit_template_delete')">
									<a-icon type="delete" />
									{{l('Delete')}}
								</a-button>

                                <a-button type="primary" @click="copy" v-if="isGranted('audit_template_copy')">
									<a-icon type="copy" />
									{{l('Copy')}}
								</a-button>

							</a-col>
						
							<a-col :span="14" class="d-flex justify-content-end">
								<a-input-search :placeholder="l('please_enter_a_name_to_search')" v-model.trim="filterText" enterButton
									style="width: 200px;margin-left: 10px;" @search="filteronchange"></a-input-search>
							</a-col>
						</a-row>
						<a-spin :spinning="isLoading">
							<a-table :columns="columns" :data-source="tableData" :customRow="customRow"
								:row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
								:rowKey="(record)=>record.id" :scroll="{x:800,y:610}" :pagination="false" size="small">
								<span slot="isWalkAudit" slot-scope="text">
									 	<span v-if="text"><a-icon type='check' style="color: #1890ff;"/></span>
									 	<span v-else><a-icon type='close'/></span>
									  </span>
						
								<span slot="enable" slot-scope="text">
									 	<span v-if="text"><a-icon type='check' style="color: #1890ff;"/></span>
									 	<span v-else><a-icon type='close'/></span>
									  </span>
						
								<span slot="action" slot-scope="text,record">
									<a @click.stop="createOrEditMachineSupplier(record)" v-if="isGranted('audit_template_edit')">
										<a-icon type="edit"/>{{l('Edit')}}
									</a>
									<font v-if="isGranted('audit_template_delete')&&isGranted('audit_template_edit')"> | </font>
									<a @click.stop="deletetable(record)" v-if="isGranted('audit_template_delete')">
										<a-icon type="delete" />{{l('Delete')}}
									</a>
								</span>
							</a-table>
						</a-spin>
						<a-pagination
						        class="pagination"
						        :total="totalItems"
						        showSizeChanger
						        showQuickJumper
								:show-total="total => `共有 ${totalItems} 条`"
								:defaultPageSize="request.maxResultCount"
								:pageSizeOptions="pageSizeOptions"
						        @change="onChangePage"
						        @showSizeChange="showSizeChange"/>
					</a-col>
				</a-row>
			</a-card>
		</a-config-provider>
	</div>
</template>

<script>
	import {
		AppComponentBase
	} from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import {
		AuditTemplateServiceProxy,AuditTemplateGroupServiceProxy
	} from '../../../shared/service-proxies';
	import {
		ModalHelper
	} from '@/shared/helpers';
	import CreatErrorCode from "./new-template/new-template.vue";
	import CreatPoint from "./create-point/create-point.vue";
    import copy from "./copy/copy.vue";

	let _this;
	export default {
		name: "organization-structure",
		mixins: [AppComponentBase],
		components: {
			CreatErrorCode
		},
		created() {
			_this = this;
			this.AuditTemplateServiceProxy = new AuditTemplateServiceProxy(this.$apiUrl, this.$api);
			this.AuditTemplateGroupServiceProxy = new AuditTemplateGroupServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.getTree();
			this.getData();
			
			window.addEventListener('resize', this.changeHeight);
			this.changeHeight();
		},
		data() {
			return {
				zh_CN,
				isEdit: false,
				isLook: false,

				filterText: undefined,
				totalItems: 0, //总数
				// 当前页码
				pageNumber: 1,
				// 共多少页
				totalPages: 1,
				// 条数显示范围
				pagerange: [1, 1],
				// 显示条数
				pageSizeOptions: ["10", "20", "50", "100", "500"],
				request: {
					maxResultCount: 20,
					skipCount: 0
				},

				tableData: [],
				columns: [{
						title: this.l('Name'),
						width: 200,
						dataIndex: 'name',
						align: 'center',
					},
					{
						title: this.l('auditTemplate.isWalkAudit'),
						width: 100,
						dataIndex: 'isWalkAudit',
						scopedSlots: {
							customRender: "isWalkAudit"
						},
						align: 'center',
					},
					{
						title: this.l('auditpoint.enable'),
						width: 60,
						dataIndex: 'enable',
						align: 'center',
						scopedSlots: {
							customRender: "enable"
						},
						ellipsis: true
					},
					{},
					{
						title: this.l('Actions'),
						width: 150,
						dataIndex: 'action',
						align: 'center',
						scopedSlots: {
							customRender: "action"
						},
						fixed: 'right',
					},
				],
				selectedRowKey: [], // 选择的数据Id
				selectedRow: [], //选择的整行数据
				isLoading: false, //加载中
				children: [],
				
				treeData: [],
				treeReplaceFields: {
				    title: 'name',
				    key: 'id',
				    code: 'code'
				},
				treeReplace: {
				    title: 'name',
				    key: 'id',
				    value: 'id'
				},
				styObj:{
					height:0
				},
				
				isTreeLoading: false,//加载中
				checkedTreeKeys: [],
				expandedTreeKeys: [],
				selectedTreeKeys: [],
				
				auditTemplateGroupId: undefined
			}
		},
		methods: {

            copy(){
                if (this.selectedRowKey.length != 1) {
					abp.message.warn(this.l("PleaseSelectOneRecord"));
					return;
				}
                ModalHelper.create(copy, {
					id: this.selectedRowKey[0]
				}, {
                    width:500
				}).subscribe(res => {
					this.getData();
					this.selectedRowKey = [];
				})
            },

			//div高度
			changeHeight(){
					this.styObj.height=window.innerHeight-199+'px';
			},
			
			//获取审核点数据
			getTree() {
				this.treeData = [];
				this.isTreeLoading = true;
				this.AuditTemplateGroupServiceProxy.getPaged(
				).finally(() => {
				    this.isTreeLoading = false;
				}).then(rel => {
					this.treeData = [];
					if(rel.length > 0){
					   rel.forEach(item=>{
					       this.treeData.push(item)
					   });
					}
					
					this.expandedTreeKeys.push('null');
					this.recursion(rel);
				})
			},
			
			//获取数据
			getData() {
				this.selectedRowKey = [];
				this.isLoading = true;
				this.AuditTemplateServiceProxy.getPaged(
					this.auditTemplateGroupId,
					this.filterText ? this.filterText : undefined,
					undefined,
					this.request.maxResultCount,
					this.request.skipCount
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					console.log(res);
					this.tableData = res.items;
					
					this.totalItems = res.totalCount;
					this.totalPages = Math.ceil(
					    res.totalCount / this.request.maxResultCount
					);
					this.pagerange = [
					    (this.pageNumber - 1) * this.request.maxResultCount + 1,
					    this.pageNumber * this.request.maxResultCount,
					];
				})
			},
			//新增
			createOrEdit(cases) {
				ModalHelper.create(CreatErrorCode, {
					isEdit: false,
					id: undefined
				}, {
				}).subscribe(res => {
					console.log(res)
					this.getData();
					this.selectedRowKey = [];
				})
			},
			//编辑
			createOrEditMachineSupplier(record) {
				// ModalHelper.create(CreatErrorCode, {
				// 	isEdit: true,
				// 	id: record.id
				// }, {
				// 	width: 400
				// }).subscribe(res => {
				// 	console.log(res)
				// 	this.getData();
				// 	this.selectedRowKey = [];
				// })
				ModalHelper.create(CreatErrorCode, {
					isEdit: true,
					id: record.id
				}, {
				}).subscribe(res => {
					this.getData();
					this.selectedRowKey = [];
				})
			},
			//批量删除事件
			batchdelete() {
				if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
					abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
					return;
				}
				console.log(this.children);
				if (this.children[0] != undefined) {
					abp.message.warn(this.l("cannot_be_deleted"));
					return;
				}
				this.$confirm({
					title: this.l("ConfirmInformation"),
					content: this.l("the_selected_option"),
					cancelText: this.l('Cancel'),
					okText: this.l('Ok'),
					onOk: () => {
						this.AuditTemplateServiceProxy.batchDelete(this.selectedRowKey).then(res => {
							this.getData();
							this.$notification["success"]({
								message: this.l("SuccessfullyDeleted"),
							});
						})
					}
				})
			},
			//删除
			deletetable(record) {
				this.$confirm(
				    {
				        title: this.l("ConfirmInformation"),
				        content: this.l("the_selected_option"),
				        cancelText: this.l('Cancel'),
				        okText: this.l('Ok'),
				        onOk: () => {
				            this.AuditTemplateServiceProxy.delete(record.id).then(res => {
				                this.getData();
				                this.$notification["success"]({
				                    message: this.l("SuccessfullyDeleted"),
				                });
				            })
				        }
				    })
			},
			//行选择
			customRow: (record) => {
				return {
					on: {
						click: () => {
							_this.onSelect(record)
						}
					}
				}

			},
			//多选
			SelectAll(record, rows, crows) {
				if (record) {
					//更改表格数据勾选状态
					this.tableData.map(item => {
						item.checked = true;
					});
					rows.forEach((item, index) => {
						//判断是否存在
						let u = this.selectedRowKey.find(t => t == item.id);
						if (!u) {
							this.selectedRowKey = [...this.selectedRowKey, item.id];
						}
					});
				} else {
					//更改表格数据勾选状态
					this.tableData.forEach((item, index) => {
						item.checked = false;
						this.selectedRowKey = [];
					});
				}
			},
			//分页
			onChangePage(page, pageSize) {
			    this.selectedRowKey = [];
			    this.pageNumber = page;
			    this.request.skipCount = (page - 1) * this.request.maxResultCount;
			    this.getData();
			},
			//正常分页展示形式，显示（共有 {0} 条）
			// showTotalFun() {
			// 	console.log(this.totalItems);
			//     return this.l(
			//         "GridFooterDisplayText",
			//         this.totalItems
			//     );
			// },
			showSizeChange(current, size) {
			    this.selectedRowKey = [];
			    this.pageNumber = 1;
			    this.request.maxResultCount = size;
			    this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
			    this.getData();
			},
			// 重写复选框点击事件(不可删除)
			onSelect(record) {
				//debugger;
				console.log(record);
				record.checked = !record.checked;
				if (record.checked) {
					this.selectedRowKey.push(record.id);
					this.children.push(record.children);
				} else {
					this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
					this.children = _.difference(_this.children, [record.children]);
				}
			},
			//搜索框点击事件
			filteronchange() {
				this.pageNumber = 1;
				this.request.skipCount = 0;
				this.getData();
			},
			
			//审核项分组选择
			onTreeSelect(selectedKeys, info) {
				this.auditTemplateGroupId = undefined;
				this.selectedTreeKeys = [];
				this.auditTemplateGroupId = selectedKeys[0];
				this.selectedTreeKeys = selectedKeys;
			    this.getData();
			},
			
			recursion(rel) {
			    if (rel.length > 0) {
			        for (let i = 0; i < rel.length; i++) {
			            // console.log(rel[i].id)
			            this.expandedTreeKeys.push(rel[i].id)
			            this.recursion(rel[i].children);
			        }
			    } else {
			        return
			    }
			},
			
			onTreeExpand(expandedKeys, record) {
			    this.expandedTreeKeys = expandedKeys;
			},
			
			//审核项分组新增
			create() {
			    ModalHelper.create(CreatPoint, {
			        isEdit: false
			    },{width:400}).subscribe(res => {
			        console.log(res)
					if(res){
						this.getTree();
					}
			    })
			},
			//审核项分组编辑
			revise() {
			    ModalHelper.create(CreatPoint, {
			        isEdit: true,
			        id: this.auditTemplateGroupId
			    },{width:400}).subscribe(res => {
					if(res){
						this.getTree();
					}
			    })
			},
			
			//审核点分组删除
			pointDatchdelete(){
				if (this.auditTemplateGroupId <= 0 || this.auditTemplateGroupId == null) {
				    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
				    return;
				}
				this.$confirm(
				    {
				        title: this.l("ConfirmInformation"),
				        content: this.l("the_selected_option"),
				        cancelText: this.l('Cancel'),
				        okText: this.l('Ok'),
				        onOk: () => {
				            this.AuditTemplateGroupServiceProxy.delete(this.auditTemplateGroupId).then(res => {
				                this.getTree();
				                this.$notification["success"]({
				                    message: this.l("SuccessfullyDeleted"),
				                });
				            })
				        }
				    })
			},
		}
	}
</script>

<style>
	.pagination {
		margin: 10px auto;
		text-align: right;
	}
</style>
